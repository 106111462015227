/* ProfessionalTable.css */
.container {
    margin-top: 20px;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table th,
  .custom-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
    
  }
  
  .custom-checkbox {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  
  .custom-checkbox:checked {
    background-color: #007bff; /* Change background color when checked */
    border-color: #007bff; /* Change border color when checked */
  }
  
  .custom-checkbox:focus {
    outline: none; /* Remove focus outline */
  }
  

  
  .custom-checkbox:hover:not(:checked) {
    background-color: #f0f0f0; /* Change background color on hover */
  }
  
  

  .custom-input {
    width: 180px;
    text-align: center;
    padding: 6px 8px; /* Adjust padding */
    border: 1px solid #ccc; /* Add border */
    border-radius: 4px; /* Add border radius */
  }
  
  .custom-input:focus {
    outline: none; /* Remove focus outline */
    border-color: #007bff; /* Change border color when focused */
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25); /* Add focus shadow */
  }
  
  .custom-button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .custom-button:hover {
    background-color: #0056b3;
  }


  .checkout-container {
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
  }
  
  .checkout-heading {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .selected-books-container {
    margin-bottom: 20px;
  }
  
  .selected-books-heading {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .selected-books-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .selected-books-table th,
  .selected-books-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .total-section {
    text-align: right;
  }
  
  .total-text {
    font-size: 1.5rem;
  }

  .con-abc{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #0056b3; */
  }

  .file-input-container {
    position: relative;
  }
  
  .file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  .file-label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border: 2px dotted #333;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    height: 100px;
    
  }
  
  .file-label:hover {
    background-color: #f0f0f0;
  }

  .col-6{
    height: 100px;
    width: 45%;
    /* background-color: #333; */
  }

  .col-7{
    height: 100px;
    width: 100%;
  }
  

  .remarks{
    width: 100%;
    height: 100px;
    margin-left: 20px;
    padding: 10px 10px;
  }