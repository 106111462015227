@tailwind base;
@tailwind components;
@tailwind utilities;

.mainContainer{
  padding: 0;
}

.text-danger {
  color: red;
}

small.text-danger {
  font-size: 13px;
  margin: -10px 0 8px 8px;
}

/* .my-des-alt * {
  display: inline;
  font-size: 0.875rem;
  font-weight: 400;
  color: #fff !important;
  background-color: transparent !important;
} */
.my-des-alt {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: pre-line;
  overflow: hidden;
  -webkit-line-clamp: 1;
  word-break: break-all;
}

.mars-pagination {
  display: flex;
  list-style: none;
  justify-content: center;
}

.mars-page a {
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  display: inline-block;
}

.mars-page.active {
  background-color: #febe10;
  border-radius: 5px;
}

.mars-page.active a,
.mars-page.active a:hover {
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}

.mars-page:hover:not(.active),
.mars-page:hover:not(.active) a {
  color: #febe10;
  text-decoration: none;
  background-color: #ddd;
  border-radius: 5px;
}

.mars-input {
  width: 100%;
  padding: 10px 12px;
  font-size: 1rem;
  outline: none;
}


#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #989898;
  color: white;
}

.filter-label {
  margin-right: 12px;
  font-weight: bold; /* Making the label bold for emphasis */
}

.form-select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 150px; /* Adjust width as needed */
}

/* Style select on hover and focus */
.form-select:hover,
.form-select:focus {
  border-color: #FECB40; /* Change border color on hover or focus */
  outline: none; /* Remove default focus outline */
}

.form-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 150px;
}

/* Style input on hover and focus */
.form-input:hover,
.form-input:focus {
  border-color: #007bff;
  outline: none;
}


.toggle-switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 28px;
  margin-left: 20px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #FEC21E;
}

input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.toggle-slider.round {
  border-radius: 34px;
}

.toggle-slider.round:before {
  border-radius: 50%;
}


.password-form-container {
  
  margin: auto; /* Centers horizontally */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers vertically */
  align-items: center; /* Centers horizontally */
  height: 100vh; /* Ensures the container takes up the full viewport height */
  
  
}

.pass{
  width: 45rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 3rem;
}

.form-group {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input {
  width: calc(100% - 20px);
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #FEC21E;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.text-red{
  color: red;
}

.disabled{
  pointer-events: none;
  opacity: 0.5;
}
.input-show{
  display: flex; 
  align-items: center;
}

.password-toggle{
  margin-left: 12px;
  cursor: pointer;
}
